import React, {useState} from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faClock, faEnvelope, faMapMarkerAlt, faPhone} from '@fortawesome/free-solid-svg-icons';
import '../scss/Main.scss'

export default function Contact({location}) {

    const encode = (data) =>{
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const [formState, setFormState] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    });

    function handleChange(e){
        e.preventDefault();
        setFormState({
            ...formState,
           [e.target.name] : e.target.value,
        })
    }

    function handleSubmit(e){
        fetch("/", {
            method: "POST",
            headers: {"Content-type": "application/x-www-form-urlencoded"},
            body: encode({"form-name" : "castl-contact-page-form", ...formState})
        })
            .then(() => alert("Message Sent!"))
            .catch(error => alert(error));

        
        setFormState({
            name: "",
            email: "",
            phone: "",
            message: "",
        });

        e.preventDefault();
    }

    return (
        <div className="contact-page-container">
            <Layout pageName={location.pathname}>
                <div className="header-image-container">
                    <div className="image-overlay">
                        <h1>CONTACT</h1>
                    </div>
                    <StaticImage
                        src="../images/kitchen.jpg"
                        loading="eager"
                        placeholder="dominantColor"
                        layout="constrained"
                        alt="image of newly remodeled basement"
                        className="image-container"
                        formats={["auto", "wepb"]}
                        objectFit="cover"
                        objectPosition="50% 50%"
                    />
                </div>

                <div className="contact-info">

                    <div className="contact-us-info">
                        <h2>
                            CONTACT US
                        </h2>
                        <ul>

                            <li>
                                <a className="contact-link" href="https://maps.google.com/?q=Farifax, Virginia">
                                    <span><FontAwesomeIcon className="fa-icon" icon={faMapMarkerAlt}/></span>
                                    <span>
                                        <h3>Fairfax, VA</h3>
                                    </span>
                                </a>
                            </li>

                            <li>
                                <a className="contact-link" href="tel:+17037550958">
                                    <span><FontAwesomeIcon className="fa-icon" icon={faPhone}/></span>
                                    <span>
                                        <h3>(703) 755-0958</h3>
                                    </span>
                                </a>
                            </li>

                            <li>
                                <a className="contact-link" href="mailto:office@castlconstruction.com">
                                    <span><FontAwesomeIcon className="fa-icon" icon={faEnvelope}/></span>
                                    <span>
                                        <h3>Office@castlconstruction.com</h3>
                                    </span>
                                </a>
                            </li>

                            <li>
                                <span><FontAwesomeIcon className="fa-icon" icon={faClock}/></span>
                                <span className="hours">
                                    <h3>Monday-Friday:</h3>
                                    <h3> 8:00am - 5pm </h3>
                                    <h3> Saturday-Sunday:</h3>
                                    <h3> CLOSED </h3>
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className="contact-us-form-wrapper">
                        <h2> GET IN TOUCH</h2>
                        <form 
                            name="castl-contact-page-form" 
                            method="POST" 
                            data-netlify="true" 
                            data-netlify-honeypot="bot-field"
                            onSubmit={handleSubmit}
                        >
                            <input type="hidden" name="form-name" value="contact-page-form" />

                            <div className="form-item">
                                <label for="name"> Full Name <span>*</span></label>
                                <input onChange={handleChange} value={formState.name} type="text" name="name" id="name" placeholder=" Full Name *"></input>
                            </div>
                           
                            <div className="form-item">
                                <label for="email"> Email <span>*</span></label>
                                <input onChange={handleChange} value={formState.email} type="email" name="email" id="email" placeholder=" Email *"></input>
                            </div>

                            <div className="form-item">
                                <label for="phone"> Phone <span>*</span></label>
                                <input onChange={handleChange} value={formState.phone} type="tel" name="phone" id="phone" placeholder=" Phone *"></input> 
                            </div>

                            <div className="form-item">
                                <label for="message"> Message <span>*</span></label>
                                <textarea onChange={handleChange} value={formState.message} type="text-linkrea" name="message" id="message" placeholder=" Tell us about your project..."/>
                            </div>

                            <button type="submit" className="button-link">Send Message</button>

                        </form>
                    </div>
                </div>
                <iframe title="map of fairfax county" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d198880.2742751405!2d-77.39619190659263!3d38.84381222054827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b660b1fe460cd3%3A0x7d0bfa4ee1381699!2sFairfax%20County%2C%20VA!5e0!3m2!1sen!2sus!4v1618198496942!5m2!1sen!2sus" style={{border:0, width: "100vw", height: "50vh"}} allowfullscreen="" loading="lazy"></iframe>
            </Layout>
        </div>
    )
}
